import React, {useCallback, useEffect, useState} from 'react';
import './App.css';
import firebase from "firebase/app";
import "firebase/auth";
import {firebaseClientConfig} from "./firebaseConfigurations";
import parkableIcon from "./parkable.png"
import oktaIcon from "./Logo_Okta_Blue_RGB.png"
import {Spinner} from "./spinner/Spinner";
import Persistence = firebase.auth.Auth.Persistence;
import {sendSlackNotification} from "./sendSlackNotification";

const App = (props: any) => {
    const initFirebaseConfig = () => {

        var urlParams = new URLSearchParams(window.location.search);
        const config = urlParams.get("config");

        const firebaseConfig = firebaseClientConfig(config);
        if(firebaseConfig) {
            return firebase.initializeApp(firebaseConfig);
        }
        else{
            postError("Invalid configuration, config = \"" + config + "\"");
        }
        return null;
    }

    const postUser = (user: any) => {
        //@ts-ignore
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({result: true, user: user}));
    }

    const postError = (msg: string) => {
        //@ts-ignore
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({result: false, error: msg}));
    }

    const postLog = (msg: string) => {
        console.log(msg);
        //@ts-ignore
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({log: true, message: msg}));
    }

    const doSignIn = useCallback(async () => {

        if (!firebase.auth().currentUser) {
            var urlParams = new URLSearchParams(window.location.search);
            const providerId = urlParams.get("providerId");

            if(!providerId){
                postError("Provider not set");
                return false;
            }

            try {
                const provider = new firebase.auth.SAMLAuthProvider(providerId);

                const result = await firebase.auth().signInWithRedirect(provider);

            } catch (err:any) {
                postLog("doSignIn: ERROR! " + err.message);

                if(err.code === "auth/network-request-failed") {
                    postError("Unable to connect. Please make sure you are connected to the internet and try again.");
                }
                else {
                    postError(err.message);
                }

                try{
                    await sendSlackNotification("Provider " + providerId + ", ." + err);
                }
                catch(err){}
                return false;
            }
        } else {
            postLog("we have a currentUser");
            postUser(firebase.auth().currentUser);
            return true;
        }
    }, []);

    const initApp = useCallback(async () => {
        if(!initFirebaseConfig()){
            console.log("cant initialise FB");
            return;
        }

        await firebase.auth().setPersistence(Persistence.SESSION);
        console.log("persistence set to session");
        // Listening for auth state changes.
        firebase.auth().onAuthStateChanged(async (user) => {
            console.log("onAuthStateChanged ");
            if (!user) {
                console.log("no user - do sign in");
                const result = await doSignIn();
                if(!result) {
                    console.log("result failed");
                    return;
                }
            } else {
                console.log("posting user");
                postUser(user);
            }
        });

        // Result from Redirect auth flow.
        try {
            const result = await firebase.auth().getRedirectResult();
            
            if(result.user !== null){
                console.log("posting user");
                postUser(result.user);
            }
        } catch (err:any) {
            console.log("error ", err);
            //@ts-ignore
            if(err.code === "auth/network-request-failed") {
                postError("Unable to connect. Please make sure you are connected to the internet and try again.");
            }
            else {
                postError(err.message);
            }

            try{
                await sendSlackNotification(" Error in getRedirectResult, " + err);
            }
            catch(err){}
        }
    }, []);

    useEffect(() => {
        void initApp();
    }, [initApp]);


    var urlParams = new URLSearchParams(window.location.search);
    const providerId = urlParams.get("providerId");

    if(!!providerId && providerId === "saml.saml_meta__u_s___1"){
        return (<div className="bodys">
            <div className="centre">
                <img className="image" src={oktaIcon} alt={"Okta logo"}/>
            </div>
            <div className="centre">
                <Spinner />
            </div>
            <div className="info-text">
                <p>Trouble logging in?</p>
                <br/>
                <text>Make sure that:<br/></text>
                <text>  •  Popups and re-directs are not blocked in your phone's web browser</text>
                <p>  •  You're connected to your company's VPN</p>
                <br/>
                <p>For assistance, contact your IT department.</p>
            </div>
        </div>)
    }

    return (<div className={"center1"}>

        <img className="image" src={parkableIcon} alt="Parkable logo"/>
        <div className="centre">
            <Spinner />
        </div>
    </div>)

}

export default App;
